import { eventNames } from "../../../utils/constants/events";
import { images } from "../../../utils/constants/images";
import { languages } from "../../../utils/constants/languages";

export const regAssests = [
  {
    language: languages.master,
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.masterUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.masterStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.masterStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.masterStep3,
      },
    ],
  },
  {
    language: languages.spanish,
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.esUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.esStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.esStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.esStep3,
      },
    ],
  },
  {
    language: languages.portugal,
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.masterUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.masterStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.masterStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.masterStep3,
      },
    ],
  },
  {
    language: languages.chile,
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.masterUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.masterStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.masterStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.masterStep3,
      },
    ],
  },
  {
    language: languages.peru,
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.masterUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.masterStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.masterStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.masterStep3,
      },
    ],
  },
  {
    language: languages.ecuador,
    assets: [
      {
        event: eventNames.regUnfilledStep,
        img: images.masterUnfilledSteps,
      },
      {
        event: eventNames.regStep1,
        img: images.masterStep1,
      },
      {
        event: eventNames.regStep2,
        img: images.masterStep2,
      },
      {
        event: eventNames.regStep3,
        img: images.masterStep3,
      },
    ],
  },
];
