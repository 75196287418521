const getCongifData = () =>{
    const hostname = window.location.hostname;    
    if(hostname==="localhost" || hostname==="dev.gskassist.com" || hostname==="staging.gskassist.com"){
        return {
            PRIMARY_LOC: process.env.REACT_APP_PRIMARY_LOC,
            API_URL: process.env.REACT_APP_API_URL,
            BOT_URL: process.env.REACT_APP_BOT_URL,
            WABA_BOT_URL: process.env.REACT_APP_WABA_BOT_URL,
            CHILE_BOT_URL : process.env.REACT_APP_CHILE_BOT_URL,
            CHILE_BOT_LOC: process.env.REACT_APP_CHILE_LOC,
            PERU_BOT_URL : process.env.REACT_APP_PERU_BOT_URL,
            PERU_BOT_LOC: process.env.REACT_APP_PERU_LOC,
            ECUADOR_BOT_URL : process.env.REACT_APP_ECUADOR_URL,
            ECUADOR_BOT_LOC: process.env.REACT_APP_ECUADOR_LOC,
            SPAIN_BOT_URL : process.env.REACT_APP_SPAIN_BOT_URL,
            SPAIN_BOT_LOC: process.env.REACT_APP_SPAIN_LOC,
            PORTUGAL_BOT_URL : process.env.REACT_APP_PORTUGAL_URL,
            PORTUGAL_BOT_LOC: process.env.REACT_APP_PORTUGAL_LOC            
        }
    }
}



export const envConfig = getCongifData();