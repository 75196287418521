import { Box } from "@mui/material";
import React from "react";
import { images } from "../../utils/constants/images";

const SplashScreen = () => {
  return (
    <Box
      className="absolute fullWidth fullvh flex ai-c jc-c"
      bgcolor={"#f0efed"}
      zIndex={99999999}
    >
      <img
        alt="gks-logo"
        src={images.gskAnimation}
        style={{ width: "300px", objectFit: "contain" }}
      />
    </Box>
  );
};

export default SplashScreen;
