import "./styles.css";
import { Box, Divider, Typography } from "@mui/material";
import { themeColors } from "../../utils/constants/themeColors";
import { useSelector } from "react-redux";
import { Locale, WabaToken } from "../../utils/redux";
import CustomAccordian from "../CustomAccordian/CustomAccordian";
import { OrderHistory } from "../../utils/types";
import { FC } from "react";
import dayjs from "dayjs";

interface CloseOrderCardProps {
  closeOrder: OrderHistory;
  color: string;
  background: string;
  iconColor: string;
}

const CloseOrderCard: FC<CloseOrderCardProps> = ({
  closeOrder,
  color,
  background,
  iconColor,
}) => {
  const locale = useSelector(Locale);
  const wabaToken = useSelector(WabaToken);

  return (
    <CustomAccordian
    padding={wabaToken ? "15px 14px 16px 14px" : undefined}
    customHeight={wabaToken ? 90 : 65}
    expandedHeight={wabaToken ? 90 : 65}
      sx={{ paddingTop: 0 }}
      title={
        <Box className="fullWidth">
          <Box className="flex jc-sb" mr={"10px"}>
            <Typography fontSize={14}>
              {closeOrder.wholesalerName} - {closeOrder.accountCode}
            </Typography>
            <Typography fontSize={14}>
              {dayjs(closeOrder.orderedDateString).format("MMM D, YYYY")}
            </Typography>
          </Box>
          <Box className="close-order-card-data" mr={"10px"} mt={"5px"}>
            <Typography className="flex g5">
              <Typography color={themeColors.primaryType1} component="span">
                {locale?.general?.common?.labels?.orderNo}
              </Typography>
              {closeOrder.orderId}
            </Typography>
            <Typography className="flex g5">
              <Typography color={themeColors.primaryType1} component="span">
                {locale?.general?.common?.labels?.orderStatus}:
              </Typography>
              {closeOrder.status}
            </Typography>
          </Box>
        </Box>
      }
      background={background}
      color={color}
      iconColor={iconColor}
    >
      <Box>
        <Typography className="locale-products-label">
          {locale?.general?.common?.labels?.products}
        </Typography>
        <Box minWidth={"auto"} overflow={"auto"}>
          <Box className="flex ai-fe">
            <Typography flex={2} color={themeColors.primaryType1}>
              {locale?.general?.common?.labels?.sku}
            </Typography>
            <Typography flex={1} color={themeColors.primaryType1}>
              {locale?.general?.common?.labels?.estPrice}
            </Typography>
            <Typography flex={1} color={themeColors.primaryType1}>
              {locale?.general?.common?.labels?.units}
            </Typography>
            <Typography flex={1} color={themeColors.primaryType1}>
              {locale?.general?.common?.labels?.discount}
            </Typography>
          </Box>
          <Divider
            sx={{
              background: "white",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          />
          {closeOrder.orderLineItems.map((order) => {
            return (
              <Box className="flex" key={order.skuIdentifier}>
                <Typography flex={2} fontSize={15} marginBottom="5px">
                  {order.sku}
                </Typography>
                <Typography flex={1}>
                  {locale?.general?.common?.labels?.currency} {order.price}
                </Typography>
                <Typography flex={1}>{order.units}</Typography>
                <Typography flex={1}>{order.onInvoiceDiscount}%</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </CustomAccordian>
  );
};

export default CloseOrderCard;
