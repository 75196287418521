import { createSlice } from "@reduxjs/toolkit";

interface LabelsData {
  language: string;
  general: {
    skuOrdering: {
      skuOrdering: {
        date_placeholder: string;
        total_value: string;
        sku_th: string;
        download_tooltip: string;
        no_order_history: string;
        discount: string;
        products_th: string;
        final_value: string;
        cancel_order: string;
        delete_tooltip: string;
        wholesaler_placeholder: string;
        price: string;
        drop_changes_yes: string;
        wholesaler_error: string;
        no_date_available: string;
        total_units: string;
        filter_placeholder: string;
        new_order_button: string;
        date_history: string;
        keep_order: string;
        on_invoice_discount_history: string;
        order_details_history: string;
        go_back: string;
        deleteOrderMessage: string;
        placing_new_order: string;
        maxVolumeError: string;
        cancel_message: string;
        units_th_history: string;
        total_discount: string;
        close_order: string;
        confirm_button: string;
        order_details: string;
        filter_error: string;
        edit_button: string;
        cleara_button: string;
        drop_changes_no: string;
        units_th: string;
        unit_select_error: string;
        order_success_message: string;
        table_discount: string;
        currency: string;
        cancel_button: string;
        no_of_phar_error: string;
        confirm_pop_up_button: string;
        api_error: string;
        open_order: string;
        noOfPhar: string;
        edit_tooltip: string;
        order_confirm_message: string;
        ok_button: string;
        editing_order: string;
        date_error: string;
        order_no_history: string;
        drop_changes: string;
        more_details_tooltip: string;
        period_orders: string;
        sku_th_history: string;
        order_delete_success: string;
      };
    };
    common: {
      buttons: {
        cancel: string;
        confirm: string;
        no: string;
        yes: string;
        continue: string;
        back: string;
      };
      tooltip: {
        moreDetails: string;
        edit: string;
        delete: string;
      };
      labels: {
        orderNo: string;
        endDate: string;
        discount: string;
        orderStatus: string;
        orderHistory: string;
        units: string;
        products: string;
        estPrice: string;
        offInvoiceDiscount: string;
        orderDetails: string;
        onInvoice: string;
        currency: string;
        onInvoiceDiscount: string;
        sku: string;
        value: string;
        orderDate: string;
        offInvoice: string;
        startDate: string;
        openOrders: string;
        status: string;
        closedOrders: string;
        bonusUnits: string;
        payTerms: string;
        freeGoods: string;
        freeGoodsIndicator: string;
      };
    };
    ordering: {
      snackbars: {
        exceedMaxLimit: string;
        noMoreDate: string;
      };
    };
    profile: {
      buttons: {
        unregister: string;
        addAccount: string;
      };
      tooltip: {
        pendingVerification: string;
        verified: string;
        unverified: string;
      };
      labels: {
        lastName: string;
        activeAccount: string;
        mobileNumber: string;
        marketingCampaigns: string;
        legalId: string;
        offerInformation: string;
        accountNumber: string;
        inactiveAccount: string;
        firstName: string;
        associatedAccounts: string;
        userPreference: string;
        profileDetails: string;
        instantMessaging: string;
        userPreferences: string;
        sms: string;
        contentPreference: string;
        notificationsChannels: string;
        email: string;
        transactional: string;
      };
    };
    orderHistory: {
      labels: {
        emptyOrder: string;
      };
    };
    brandSelection: {
      buttons: {
        checkout: string;
      };
      labels: {
        chooseBrands: string;
      };
    };
    representative: {
      buttons: {
        clear: string;
      };
      labels: {
        validAccounts: string;
        offers: string;
        lastName: string;
        access: string;
        activity: string;
        lastOrder: string;
        mobile: string;
        planProgress: string;
        filterClient: string;
        consent: string;
        userId: string;
        lastConversation: string;
        firstName: string;
        customerList: string;
        email: string;
        order: string;
      };
    };
    userHeader: {
      tooltip: {
        help: string;
        logout: string;
        changeUser: string;
        profile: string;
      };
    };
    homePage: {
      labels: {
        lastOrder: string;
        planProgress: string;
      };
    };
    whatsAppOrder: {
      labels: {
        expiredLink: string;
      }
    }
    order: {
      buttons: {
        selectWholesaler: string;
      };
      tooltip: {
        prevDraftError: string;
        confirmDraftChanges: string;
      };
      labels: {
        selectDate: string;
        draftStatus: string;
      };
    };
    pageError:{
      labels: {
        pageNotFound: string;
        pageNotExist: string;
      }
    }
  };
}


interface LocState {
  locale: LabelsData;
  loc: string;
}

const locSlice = createSlice({
  name: "locSlice",
  initialState: {
    locale: {
      language: ""
    },
    loc: null
  },
  reducers: {
    setLocale(state:any, action) {
      state.locale = action.payload;
    },
    setLoc(state, action) {
      state.loc = action.payload;
    }
  },
});

export default locSlice.reducer;

export const { setLocale, setLoc } = locSlice.actions;

export const Locale = (state: { locSlice: LocState }) => state.locSlice.locale;
export const Loc = (state: { locSlice: LocState }) => state.locSlice.loc;