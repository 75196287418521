import { Box, CircularProgress } from "@mui/material";
import { themeColors } from "../../utils/constants/themeColors";
import { FC } from "react";

interface LoadingIconProps{
  fullScreen?: boolean;
}

const LoadingIcon : FC<LoadingIconProps> = ({fullScreen}) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        height:'100%',
        alignItems: "center",
        width: fullScreen ? "100%" : "40%",
        justifyContent: "center",
        zIndex: 99999999999,
      }}
    >
      <CircularProgress sx={{ color: themeColors.primaryType1 }} />
    </Box>
  );
};

export default LoadingIcon;
