import gskAnimation from "../../assets/gif/gsk-animation.gif";
import esUnfilledSteps from "../../assets/images/registration/spain/unfilled-steps.jpg";
import esStep1 from "../../assets/images/registration/spain/step1.jpg";
import esStep2 from "../../assets/images/registration/spain/step2.jpg";
import esStep3 from "../../assets/images/registration/spain/step3.jpg";
import masterUnfilledSteps from "../../assets/images/registration/master/unfilled-steps.jpg";
import masterStep1 from "../../assets/images/registration/master/step1.jpg";
import masterStep2 from "../../assets/images/registration/master/step2.jpg";
import masterStep3 from "../../assets/images/registration/master/step3.jpg";
import enBanner from "../../assets/images/welcome/enBanner.jpg";
import esBanner from "../../assets/images/welcome/esBanner.jpg";
import esTopBanner from "../../assets/images/topbanner/esTopBanner.jpg";
import enTopBanner from "../../assets/images/topbanner/enTopBanner.jpg";
import { languages } from "./languages";
import oragneBg from "../../assets/images/welcome/orangeBg.png"
import enBannerAssets from "../../assets/images/welcome/enBannerAssets.png"
import esBannerAssets from "../../assets/images/welcome/esBannerAssets.png"
import bannerTemplate from "../../assets/images/gsk-banner-template.jpg"

export const images = {
  gskAnimation,
  esUnfilledSteps,
  esStep1,
  esStep2,
  esStep3,
  masterUnfilledSteps,
  masterStep1,
  masterStep2,
  masterStep3,
  esBanner,
  enBanner,
  oragneBg,
  enBannerAssets,
  esBannerAssets,
  bannerTemplate
};

export const topBannerAssets = [
  {
    language: languages.master,
    banner: enTopBanner,
  },
  { language: languages.spanish, banner: esTopBanner },
  {
    language: languages.chile,
    banner: enTopBanner,
  },
  {
    language: languages.peru,
    banner: enTopBanner,
  },
  {
    language: languages.ecuador,
    banner: enTopBanner,
  },
  {
    language: languages.portugal,
    banner: enTopBanner,
  },
];
