/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from "react";
import { Box } from "@mui/material";
import axiosInstance from "../../utils/axios";
import { URLS } from "../../utils/constants/urls";
import { findLocaleLang } from "../../utils/helperFunctions/helperFunction";
import { Locale, setLocale } from "../../utils/redux";
import { useDispatch, useSelector } from "react-redux";

interface NotFoundProps {
  loc: string;
}

const NotFound: FC<NotFoundProps> = ({ loc }) => {
  const dispatch = useDispatch();
  const locale = useSelector(Locale);

  const getLocalizationsData = async () => {
    try {
      const { data } = await axiosInstance(URLS.localization(loc));
      if (data) {
        const findLocale = findLocaleLang(data);
        if (findLocale) {
          dispatch(setLocale(findLocale));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocalizationsData();
  }, []);

  return (
    <Box className="flex ai-c jc-c" height={"100vh"}>
      <Box>
        <h2>404 - {locale?.general?.pageError?.labels?.pageNotFound}</h2>
        <p>{locale?.general?.pageError?.labels?.pageNotExist}</p>
      </Box>
    </Box>
  );
};

export default NotFound;
