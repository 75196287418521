import "./styles.css";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import CustomAccordian from "../CustomAccordian/CustomAccordian";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { themeColors } from "../../utils/constants/themeColors";
import { FC, useState } from "react";
import DatePicker from "react-datepicker";
import { CartOrder } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentScreen,
  FilteredApiData,
  Locale,
  OrderData,
  setLoading,
  setOrderData,
  WabaToken,
} from "../../utils/redux";
import dayjs from "dayjs";
import {
  changeOrderAccount,
  changeQuantity,
  deleteOrderFromEditOrderFlow,
  getTriad,
} from "../../utils/helperFunctions/helperFunction";
import DialogBox from "../DialogBox/DialogBox";
import {
  AccountsWithTriad,
  SelectedBrand,
} from "../../utils/redux/reducer/order-slice";
import { offerTypes } from "../../utils/constants/constatns";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { sendEvent } from "../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../utils/constants/events";
import axiosInstance from "../../utils/axios";
import { URLS } from "../../utils/constants/urls";
import WarningIcon from "@mui/icons-material/Warning";
import CustomButton from "../CustomButton/CustomButton";

interface OpenOderCardProps {
  order: CartOrder;
  disableDates: {
    triad: string;
    dateToExclude: string[];
  }[];
  hideAddWholesaler: boolean;
}

const OpenOderCard: FC<OpenOderCardProps> = ({
  order,
  disableDates,
  hideAddWholesaler,
}) => {
  const [open, setOpen] = useState(false);
  const orderData = useSelector(OrderData);
  const dispatch = useDispatch();
  const filtredData = useSelector(FilteredApiData);
  const [showWholesalerChange, setShowWholesalerChange] = useState(false);
  const brandData = useSelector(SelectedBrand);
  const locale = useSelector(Locale);
  const [orderToDelete, setOrderToDelete] = useState<number>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const wabaToken = useSelector(WabaToken);
  const currentScreen = useSelector(CurrentScreen);

  const handleQunatity = (
    sku: {
      skuIdentifier: string;
      sku: string;
      requestedVolume: number;
      onInvoiceDiscount: number;
      price: number;
    },
    operation: string
  ) => {
    if (operation === "minus" && sku.requestedVolume === 0) {
      return;
    }
    if (operation === "plus") {
      let totalQuantity = 0;
      if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
        if (hideAddWholesaler) {
          alert(locale?.general?.ordering?.snackbars?.exceedMaxLimit);
          return;
        }
      } else {
        for (let orderItem of orderData) {
          for (let skuItem of orderItem.skuData) {
            if (skuItem.skuIdentifier === sku.skuIdentifier) {
              totalQuantity = totalQuantity + skuItem.requestedVolume;
            }
          }
        }
        const findSku = brandData.skuData.find(
          (skuItem) => skuItem.skuIdentifier === sku.skuIdentifier
        );
        if (findSku && totalQuantity >= findSku.maxVolume) {
          alert(locale?.general?.ordering?.snackbars?.exceedMaxLimit);
          return;
        }
      }
    }
    const updatedOrderData = changeQuantity(
      orderData,
      sku,
      0,
      order,
      operation
    );
    dispatch(setOrderData(updatedOrderData));
  };

  const handleInputQuantity = (
    inputValue: number,
    sku: {
      skuIdentifier: string;
      sku: string;
      requestedVolume: number;
      onInvoiceDiscount: number;
      price: number;
    }
  ) => {
    if (isNaN(inputValue)) {
      inputValue = 0;
    }
    if (inputValue < 0) {
      return;
    }
    let totalQuantity = 0;
    if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
      for (let orderItem of orderData) {
        for (let skuItem of orderItem.skuData) {
          if (orderItem.brandIdentifier === order.brandIdentifier) {
            totalQuantity = totalQuantity + skuItem.requestedVolume;
          }
        }
      }
      totalQuantity = totalQuantity - sku.requestedVolume;
      totalQuantity = totalQuantity + inputValue;
      if (totalQuantity > brandData.maxVolume) {
        alert(locale?.general?.ordering?.snackbars?.exceedMaxLimit);
        return;
      }
    } else {
      for (let orderItem of orderData) {
        for (let skuItem of orderItem.skuData) {
          if (skuItem.skuIdentifier === sku.skuIdentifier) {
            totalQuantity = totalQuantity + skuItem.requestedVolume;
          }
        }
      }
      totalQuantity = totalQuantity - sku.requestedVolume;
      totalQuantity = totalQuantity + inputValue;
      const findSku = brandData.skuData.find(
        (skuItem) => skuItem.skuIdentifier === sku.skuIdentifier
      );
      if (findSku && totalQuantity > findSku.maxVolume) {
        alert(locale?.general?.ordering?.snackbars?.exceedMaxLimit);
        return;
      }
    }
    const updatedOrderData = changeQuantity(
      orderData,
      sku,
      inputValue,
      order,
      "inputValue"
    );
    dispatch(setOrderData(updatedOrderData));
  };

  const handleDateChange = (date: Date) => {
    const updatedOrderData = orderData.map((orderItem) => {
      if (
        orderItem.brandIdentifier === order.brandIdentifier &&
        orderItem.orderDate === order.orderDate &&
        getTriad(orderItem) === getTriad(order)
      ) {
        return {
          ...orderItem,
          orderDate: dayjs(date).format("YYYY-MM-DD"),
          isDraft: true,
        };
      } else {
        return orderItem;
      }
    });
    dispatch(setOrderData(updatedOrderData));
    setOpen(false);
  };

  const getDateToDisable = () => {
    if (disableDates.length > 0) {
      const findDisableDates = disableDates.find(
        (data) => data.triad === getTriad(order)
      );
      if (findDisableDates) {
        const updatedDataInDateFormat = findDisableDates.dateToExclude
          .filter((date) => date !== order.orderDate)
          .map((date) => new Date(date));
        return updatedDataInDateFormat;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getAvailableDateForWholesaler = (
    date: string,
    existingOrder: CartOrder[],
    account: AccountsWithTriad
  ) => {
    const isExist = existingOrder.find((order) => order.orderDate === date);
    let isExistInDisableDates = false;
    for (let datesWithTriad of disableDates) {
      if (datesWithTriad.triad === account.triad) {
        const findDate = datesWithTriad.dateToExclude.find(
          (dateValue) => dateValue === date
        );
        if (findDate) {
          isExistInDisableDates = true;
        }
        break;
      }
    }
    if (isExist || isExistInDisableDates) {
      if (date === filtredData.endDate) {
        alert(locale?.general?.ordering?.snackbars?.noMoreDate);
      } else {
        let nextDate = dayjs(date).add(1, "day").format("YYYY-MM-DD");
        getAvailableDateForWholesaler(nextDate, existingOrder, account);
      }
    } else {
      const updatedOrderData = changeOrderAccount(
        orderData,
        order,
        account,
        date
      );
      dispatch(setOrderData(updatedOrderData));
      setOpen(true);
    }
  };

  const handleWholesalerChange = (account: AccountsWithTriad) => {
    const existingOrder = orderData.filter(
      (orderItem) => getTriad(orderItem) === account.triad
    );
    if (existingOrder.length > 0) {
      const sameDateOrderExist = existingOrder.find(
        (orderItem) => orderItem.orderDate === order.orderDate
      );
      if (sameDateOrderExist) {
        getAvailableDateForWholesaler(
          dayjs().format("YYYY-MM-DD"),
          existingOrder,
          account
        );
      } else {
        const updatedOrderData = changeOrderAccount(
          orderData,
          order,
          account,
          order.orderDate
        );
        dispatch(setOrderData(updatedOrderData));
      }
    } else {
      const updatedOrderData = changeOrderAccount(
        orderData,
        order,
        account,
        order.orderDate
      );
      dispatch(setOrderData(updatedOrderData));
    }
    setShowWholesalerChange(false);
  };
  const checkValidDates = (orderDate: string, retrunNull: boolean) => {
    const today = dayjs().startOf("day");
    if (orderDate === "" || dayjs(orderDate).startOf("day").isBefore(today)) {
      return retrunNull ? (null as any) : "";
    } else {
      return retrunNull
        ? new Date(dayjs(order.orderDate).format("YYYY-MM-DD"))
        : dayjs(order.orderDate).format("MMM DD, YY");
    }
  };

  const handleDraftItemRemove = async () => {
    try {
      if (order.orderId) {
        if(wabaToken){
          setOrderToDelete(order.orderId);
          setDeleteModal(true);
        }else{
          sendEvent(eventNames.deleteOrder, order);
        }
      } else {
        const uniqueId = order.skuData
          .filter((skuItem) => skuItem.uniqueId)
          .map((elm) => elm.uniqueId);
        if (uniqueId.length > 0) {
          dispatch(setLoading(true));
          await axiosInstance.delete(URLS.deleteDrafts, {
            data: uniqueId,
          });
        }
        dispatch(setLoading(false));
        const draftAfterRemoveItem = orderData.filter(
          (orderItem) => orderItem.indexValue !== order.indexValue
        );
        dispatch(setOrderData(draftAfterRemoveItem));
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  return (
    <>
      <CustomAccordian
        defaultOpen={true}
        padding={wabaToken ? "15px 10px 16px 10px" : order.orderId ? "10px 10px 16px 10px" : undefined}
        customHeight={wabaToken && order.orderId ? 100 : wabaToken ? 60 :  order.orderId ? 65 : 38}
        expandedHeight={wabaToken && order.orderId ? 100 : wabaToken ? 60 : order.orderId ? 65 : 38}
        sx={{ paddingTop: 0, paddingLeft: "23px" }}
        isOrderDraft={order.orderId ? true : false}
        title={
          <Box className="fullWidth">
            <Box className="card-child">
              <Box className="card-child-1">
                <Typography
                  fontSize={14}
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowWholesalerChange(true);
                  }}
                >
                  {order.wholesalerName} - {order.accountCode}
                </Typography>
                <Box className="flex ai-c g5">
                {!wabaToken && order.isDraft && (
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={"rgb(166,166,166)"}
                  >
                    {locale?.general?.order?.labels?.draftStatus}
                  </Typography>
                )}
                {wabaToken && <IconButton onClick={(event) => {
                  event.stopPropagation();
                  handleDraftItemRemove();
                }} sx={{ padding: 0 }}>
                  <DeleteOutlineIcon color="primary" />
                </IconButton>}
                {!wabaToken && order.isDraft && order.orderId && (
                  <WarningIcon
                    tabIndex={-1}
                    sx={{
                      color: "warning.light",
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={
                      locale?.general?.order?.tooltip?.confirmDraftChanges
                    }
                    aria-hidden="true"
                  />
                )}
                </Box>
              </Box>

              <Box
                className="card-child-2 pointer open-order-date-picker"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Box className="flex ai-c">
                <CalendarMonthIcon
                  sx={{ fontSize: 16 }}
                  onClick={(event) => {
                    setOpen(true);
                  }}
                />
                <DatePicker
                  placeholderText={locale?.general?.order?.labels?.selectDate}
                  excludeDates={getDateToDisable()}
                  onInputClick={() => {
                    setOpen(true);
                  }}
                  popperPlacement="bottom-start"
                  readOnly={true}
                  open={open}
                  onClickOutside={() => {
                    setOpen(false);
                  }}
                  autoComplete="off"
                  className="card-date-picker"
                  dateFormat="MMM dd, yy"
                  selected={checkValidDates(order.orderDate, true)}
                  value={checkValidDates(order.orderDate, false)}
                  onChange={(date) => {
                    if (date) {
                      handleDateChange(date);
                    }
                  }}
                  minDate={new Date(new Date().toISOString().split("T")[0])}
                  maxDate={
                    !!filtredData.endDate
                      ? new Date(filtredData.endDate)
                      : new Date(new Date().toISOString().split("T")[0])
                  }
                />
                </Box>
                {!wabaToken && <IconButton onClick={handleDraftItemRemove}>
                  <DeleteOutlineIcon color="primary" />
                </IconButton>}
                <Box className='flex g5 ai-c'>
                {wabaToken && <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={"rgb(166,166,166)"}
                  >
                    {locale?.general?.order?.labels?.draftStatus}
                  </Typography>}
                  {wabaToken && order.isDraft && order.orderId && (
                  <WarningIcon
                    tabIndex={-1}
                    sx={{
                      color: "warning.light",
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={
                      locale?.general?.order?.tooltip?.confirmDraftChanges
                    }
                    aria-hidden="true"
                  />
                )}
                </Box>
              </Box>
            </Box>
            {order.orderId && (
              <Box className="card-order-id-box">
                <Typography className="flex g5 ai-c">
                  <Typography color={themeColors.primaryType1} component="span">
                    {locale?.general?.common?.labels?.orderNo}
                  </Typography>
                  {order.orderId}
                </Typography>
                <Typography className="flex g5" mr={"10px"}>
                  <Typography color={themeColors.primaryType1} component="span">
                    {locale?.general?.common?.labels?.orderStatus}:
                  </Typography>
                  {order.externalOrderStatus}
                </Typography>
              </Box>
            )}
          </Box>
        }
        showEdit={true}
        setShowAccountList={setShowWholesalerChange}
      >
        {order.skuData.map((sku) => {
          return (
            <Box className="mt5" key={sku.skuIdentifier}>
              <Box className="flex ai-c jc-sb">
                <Typography fontSize={12.5}>{sku.sku}</Typography>
                <Box className="flex us-remove">
                  <Box
                    className="quantity-handle-button minus-button flex ai-c jc-c"
                    bgcolor={themeColors.primaryType1}
                    onClick={() => handleQunatity(sku, "minus")}
                  >
                    <Typography fontWeight={500}>-</Typography>
                  </Box>
                  <Box
                    className="selected-quantity flex ai-c jc-c"
                    bgcolor={themeColors.secondaryType1}
                  >
                    <TextField
                      fullWidth={false}
                      sx={{
                        width: "75px",
                        "& .MuiOutlinedInput-root": {
                          "& input": {
                            padding: 0,
                            textAlign: "center",
                          },
                          padding: "0px",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      value={sku.requestedVolume}
                      variant="outlined"
                      onChange={(e) => {
                        handleInputQuantity(parseInt(e.target.value), sku);
                      }}
                    />
                  </Box>
                  <Box
                    className="quantity-handle-button plus-button flex ai-c jc-c"
                    bgcolor={themeColors.primaryType1}
                    onClick={() => handleQunatity(sku, "plus")}
                  >
                    <Typography fontWeight={500}>+</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </CustomAccordian>
      <DialogBox open={showWholesalerChange} setOpen={setShowWholesalerChange}>
        <Box className="p10 accountL-list-container">
          {filtredData.accounts.length === 1 ? (
            <Box
              onClick={() => {
                setShowWholesalerChange(false);
              }}
              className="account-list-item"
            >
              <Typography fontSize={14}>
                {filtredData.accounts[0].wholesalerName} -{" "}
                {filtredData.accounts[0].accountCode}
              </Typography>
            </Box>
          ) : (
            filtredData.accounts.map((account) => {
              return (
                account.triad !== getTriad(order) && (
                  <Box
                    onClick={() => {
                      handleWholesalerChange(account);
                    }}
                    key={account.triad}
                    className="account-list-item"
                  >
                    <Typography fontSize={14}>
                      {account.wholesalerName} - {account.accountCode}
                    </Typography>
                  </Box>
                )
              );
            })
          )}
        </Box>
      </DialogBox>
      <DialogBox open={deleteModal} setOpen={setDeleteModal}>
        <Box className="p15">
          <Typography>{locale?.general?.skuOrdering?.skuOrdering?.deleteOrderMessage}</Typography>
          <Box className="flex jc-e mt10">
          <CustomButton width="70px" align="center" onClick={()=>{
            deleteOrderFromEditOrderFlow(orderToDelete,currentScreen);
          }}>
          <Typography>
            {locale?.general?.common?.buttons?.yes}
            </Typography>
            </CustomButton>
          <CustomButton 
          background="#e6e6e6"
          color="#959595"
          hoverBackground="#d2cece" width="70px" align="center" onClick={()=>{
            setOrderToDelete(undefined);
            setDeleteModal(false);
          }}>
            <Typography>
            {locale?.general?.common?.buttons?.no}
            </Typography></CustomButton>
        </Box>
        </Box>
      </DialogBox>
    </>
  );
};

export default OpenOderCard;
