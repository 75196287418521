/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import { sxStyle } from "./sxStyle";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Locale, SelectedEditOrder, setCurrentScreen, setSelectedEditOrder } from "../../../utils/redux";
import EditIcon from "../../../assets/svg/edit-icon";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { OrderHistory } from "../../../utils/types";
import { eventNames } from "../../../utils/constants/events";
import { screenNames } from "../../../utils/constants/screenNames";

const formTypes = {
  type1: "type1",
  type2: "type2",
};

const tableFlex = {
  number1: 1.2,
  number2: 0.6,
  number3: 0.6,
  number4: 0.6,
};

interface OrderCardHistoryProps {
  type: any;
  orderData: OrderHistory;
  formType: any;
  showIcons: boolean;
  handleEditIcon?: (orderData: any) => void;
  setDeleteOrderId?: Dispatch<SetStateAction<any>>;
  setMessageType?: Dispatch<SetStateAction<string>>;
  setShowPopUp?: Dispatch<SetStateAction<boolean>>;
  setPopUpMessage?: Dispatch<SetStateAction<string>>;
  deleteMessage?: any;
  orderHistoryFlow?: boolean;
  setOrderHistoryFlow?: Dispatch<SetStateAction<boolean>>;
}

const OrderCardHistory: FC<OrderCardHistoryProps> = ({
  type,
  orderData,
  formType,
  showIcons,
  handleEditIcon,
  setDeleteOrderId,
  setMessageType,
  setShowPopUp,
  setPopUpMessage,
  deleteMessage,
  orderHistoryFlow,
  setOrderHistoryFlow
}) => {
  const [open, setOpen] = useState(false);
  const locale = useSelector(Locale);
  const dispatch = useDispatch();
  const selectedEditOrder = useSelector(SelectedEditOrder);
  const borderType1 = type==='open' ? '1px solid #777575' : '1px solid #dad6d6';
  const borderType2 = type==='open' ? '1px solid #dad6d6' : '1px solid #777575';

  useEffect(()=>{
    if(selectedEditOrder){
      dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
    }
  },[selectedEditOrder])

  return (
    <Box
      className="order-history-card flex br5 mb10"
      sx={sxStyle.orderHistoryCard(type)}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <Box style={{ padding: "6px 0px", marginTop: "10px" }}>
        <Box
          className="order-history-mark"
          style={{
            background: sxStyle.getStatusColor(orderData?.status, "", "bar"),
          }}
        />
      </Box>
      <Box p={"4px"} ml={"8px"} className="f1">
        <Box className="flex ai-c">
          <Box className="flex fdc fullWidth">
            <Box>
              <Box className="flex jc-sb">
                <Typography fontSize={15} fontWeight={500}>
                  {orderData?.wholesalerName} - {orderData?.accountCode}
                </Typography>
                <Box pr={"10px"} sx={sxStyle.checkGreyColor(type)}>
                  <Typography fontWeight={"bold"}>
                    {locale?.general?.skuOrdering?.skuOrdering?.date_history}:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {dayjs(orderData?.orderedDateString).format(
                        "MMM D, YYYY"
                      )}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box sx={sxStyle.checkGreyColor(type)}>
                <Typography fontSize={13} lineHeight={"22px"}>
                  {locale?.general?.skuOrdering?.skuOrdering?.order_no_history}: {orderData?.orderId}
                </Typography>
              </Box>
            </Box>
            <Box
              className="flex jc-sb"
              style={{
                color: sxStyle.getStatusColor(orderData?.status, "font", type),
              }}
            >
              <Typography fontSize={13} fontWeight={"bold"}>
                {orderData?.status}
              </Typography>
              <Box className="flex ai-c">
                {showIcons && (
                  <EditIcon
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={locale?.general?.skuOrdering?.skuOrdering?.edit_tooltip}
                    className="pointer"
                    style={{ fontSize: "17px" }}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      if(orderHistoryFlow){
                        dispatch(setSelectedEditOrder(orderData));
                      }else{
                        if (handleEditIcon) {
                          // if(orderHistoryFlow && setOrderHistoryFlow){
                          //   setOrderHistoryFlow(false);
                          // }
                          handleEditIcon(orderData);
                        }
                      }
                      sendEvent("edit-order-clicked", {
                        orderId: orderData.orderId,
                      });
                    }}
                  />
                )}
                {showIcons && (
                  <DeleteOutlineIcon
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={locale?.general?.skuOrdering?.skuOrdering?.delete_tooltip}
                    color="primary"
                    className="pointer ml5"
                    sx={{ fontSize: 21 }}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      if(orderHistoryFlow){
                        sendEvent(eventNames.deleteOrder,{ orderId: orderData.orderId })
                      }else{
                        if (setDeleteOrderId) {
                          setDeleteOrderId(orderData.orderId);
                        }
                        if (setMessageType) {
                          setMessageType("delete-order");
                        }
                        if (setShowPopUp) {
                          setShowPopUp(true);
                        }
                        if (setPopUpMessage) {
                          setPopUpMessage(deleteMessage);
                        }
                      }
                    }}
                  />
                )}
                {open ? (
                  <KeyboardArrowDownIcon
                    sx={sxStyle.moreDetails(type)}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={
                      locale?.general?.skuOrdering?.skuOrdering?.more_details_tooltip
                    }
                    aria-hidden="true"
                    tabIndex={-1}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    sx={sxStyle.moreDetails(type)}
                    data-tooltip-id="custom-tooltip"
                    data-tooltip-content={
                      locale?.general?.skuOrdering?.skuOrdering?.more_details_tooltip
                    }
                    aria-hidden="true"
                    tabIndex={-1}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {open && (
          <Box>
            <Typography p={"8px 0px"} fontWeight={"bold"} fontSize={14.5}>
              {locale?.general?.skuOrdering?.skuOrdering?.order_details_history}
            </Typography>
            <Box>
              <Box>
                <Box color="primary.main" className="history-table-container">
                  <Typography
                    className="order-history-table-th"
                    flex={tableFlex.number1}
                  >
                    {locale?.general?.skuOrdering?.skuOrdering?.sku_th_history}
                  </Typography>
                  <Typography
                    className="order-history-table-th"
                    flex={tableFlex.number2}
                  >
                    {locale?.general?.skuOrdering?.skuOrdering?.units_th_history}
                  </Typography>
                  {formType === formTypes.type1 && (
                    <Typography
                      className="order-history-table-th"
                      flex={tableFlex.number3}
                      style={{
                        paddingLeft: "2px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {locale?.general?.common?.labels?.onInvoice}
                    </Typography>
                  )}
                  {formType === formTypes.type1 && (
                    <Typography
                      className="order-history-table-th"
                      flex={tableFlex.number3}
                      style={{
                        paddingLeft: "2px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {locale?.general?.common?.labels?.offInvoice}
                    </Typography>
                  )}
                  {formType === formTypes.type1 && (
                    <Typography
                      className="order-history-table-th"
                      flex={tableFlex.number4}
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "2px",
                      }}
                    >
                      {locale?.general?.skuOrdering?.skuOrdering?.price}
                    </Typography>
                  )}
                </Box>
                <Box>
                  {orderData?.orderLineItems &&
                    orderData?.orderLineItems?.map(
                      (orderItem, index: number) => {
                        return (
                          <Box
                            className="history-data-container"
                            borderTop={
                              index === 0
                                ? borderType1
                                : index > 0 &&
                                  orderData?.orderLineItems[index]
                                    .brandIdentifier !==
                                    orderData?.orderLineItems[index - 1]
                                      .brandIdentifier
                                ? borderType1
                                : borderType2
                            }
                            key={orderItem.brandIdentifier + index}
                          >
                            <Box className="flex">
                              <Typography
                                fontWeight={"bold"}
                                flex={tableFlex.number1}
                              >
                                {orderItem.sku}
                              </Typography>
                              <Typography flex={tableFlex.number2} pl={"2px"}>
                                {orderItem?.units}
                              </Typography>
                              {formType === formTypes.type1 && (
                                <Typography flex={tableFlex.number3} pl={"2px"}>
                                  {orderItem?.onInvoiceDiscount
                                    ? orderItem?.onInvoiceDiscount + "%"
                                    : orderItem?.onInvoiceFreeGoods || "-"}
                                </Typography>
                              )}
                              {formType === formTypes.type1 && (
                                <Typography flex={tableFlex.number3} pl={"2px"}>
                                  {orderItem?.offInvoiceDiscount
                                    ? orderItem?.offInvoiceDiscount + "%"
                                    : orderItem?.offInvoiceFreeGoods || "-"}
                                </Typography>
                              )}
                              {formType === formTypes.type1 && (
                                <Typography
                                  pr={"10px"}
                                  pl={"2px"}
                                  whiteSpace={"nowrap"}
                                  flex={tableFlex.number4}
                                >
                                  {
                                    locale?.general?.skuOrdering?.skuOrdering
                                      ?.currency
                                  }{" "}
                                  {orderItem?.price}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        );
                      }
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderCardHistory;
