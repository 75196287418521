import { createSlice } from "@reduxjs/toolkit";
import { AccountsInterface, CartOrder, DiscountRanges, FilteredCart, OrderHistory } from "../../types";

export interface skuDataInterface {
  brand: string;
  brandIdentifier: string;
  ean: string;
  maxLevelReached: boolean;
  maxVolume: number;
  offerLevel: string;
  onInvoiceDiscountRanges: DiscountRanges[];
  period: {
    endDateString: string;
    period: string;
    startDateString: string;
  };
  sku: string;
  skuIdentifier: string;
  skuPrice: number;
  actualMaxVolume: number;
  skuPurchased: number;
  closedPurchased: number;
  offInvoiceDiscountRanges: DiscountRanges[];
  onInvoicePaymentTermRanges: DiscountRanges[];
  onInvoiceDiscountType: string;
  offInvoiceDiscountType: string;
  volumeRanges: DiscountRanges[];
  skuAppearOrder: number;
}
export interface FiltredApiDataInterface {
  onInvoiceDiscountType: string;
  offInvoiceDiscountType: string;
  brand: string;
  brandIdentifier: string;
  onInvoiceDiscountRanges: DiscountRanges[];
  offInvoiceDiscountRanges: DiscountRanges[];
  onInvoicePaymentTermRanges: DiscountRanges[];
  purchased: number;
  selected: number;
  offerLevel: string;
  skuData: skuDataInterface[];
  maxVolume: number;
  actualMaxVolume: number;
  closedPurchased: number;
  openPurchased: number;
  volumeRanges: DiscountRanges[];
  brandAppearOrder: number;
}

export interface AccountsWithTriad extends AccountsInterface {
  triad: string;
}

export interface FilteredApiDataType{
  offerData: FiltredApiDataInterface[];
  openOrders: OrderHistory[];
  closeOrders: OrderHistory[];
  lastOrder: OrderHistory | null;
  startDate: string;
  endDate: string;
  accounts: AccountsWithTriad[]
}

interface OrderState {
  orderData: CartOrder[];
  cart: CartOrder[];
  filteredApiData: FilteredApiDataType;
  selectedBrand: FiltredApiDataInterface;
  indexValue: number;
  selectedEditOrder: OrderHistory | null;
  checkoutData: FilteredCart[]
}

const orderSlice = createSlice({
  name: "orderSlice",
  initialState: {
    orderData: [],
    filteredApiData: {
      offerData: [],
      openOrders: [],
      closeOrders:[],
      lastOrder: null,
      startDate: "",
      endDate: "",
      accounts: []
    },
    selectedBrand: {},
    cart: [],
    indexValue: 0,
    selectedEditOrder: null,
    checkoutData: []
  },
  reducers: {
    setCart(state,action){
      state.cart = action.payload;
    },
    setOrderData(state, action) {
      const plainFilteredApiData: FilteredApiDataType = JSON.parse(JSON.stringify(state.filteredApiData));
      const payloadWithSkuAppearOrder = action.payload?.map((elm:CartOrder)=>{
        const findBrand = plainFilteredApiData.offerData.find(item => item.brandIdentifier===elm.brandIdentifier);
        const skuWithAppearOrder = elm.skuData.map((skuElm)=>{
          const findSku = findBrand?.skuData?.find(item => item.skuIdentifier===skuElm.skuIdentifier);
          return {
            ...skuElm,
            skuAppearOrder: findSku?.skuAppearOrder || -1
          }
        }).sort((a, b) => a.skuAppearOrder - b.skuAppearOrder);
        return {...elm, skuData: skuWithAppearOrder}
      });
      state.orderData = payloadWithSkuAppearOrder;
    },
    setFilteredApiData(state, action) {
      state.filteredApiData = action.payload;
    },
    setSelectedBrand(state,action){
      state.selectedBrand = action.payload;
    },
    setIndexValue(state,action){
      state.indexValue = action.payload;
    },
    setSelectedEditOrder(state,action){
      state.selectedEditOrder = action.payload;
    },
    setCheckoutData(state,action){
        state.checkoutData = action.payload;
    }
  },
});

export default orderSlice.reducer;

export const { setOrderData, setFilteredApiData, setSelectedBrand, setCart, setIndexValue, setSelectedEditOrder, setCheckoutData } = orderSlice.actions;

export const OrderData = (state: { orderSlice: OrderState }) =>
  state.orderSlice.orderData;

export const FilteredApiData = (state: { orderSlice: OrderState }) =>
  state.orderSlice.filteredApiData;

export const SelectedBrand = (state: {orderSlice: OrderState}) => state.orderSlice.selectedBrand;

export const Cart = (state: {orderSlice:OrderState}) => state.orderSlice.cart;

export const IndexValue = (state: {orderSlice:OrderState}) => state.orderSlice.indexValue;

export const SelectedEditOrder = (state: {orderSlice:OrderState}) => state.orderSlice.selectedEditOrder;

export const CheckoutData = (state: {orderSlice:OrderState}) => state.orderSlice.checkoutData;