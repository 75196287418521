/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import "./styles.css";
import { themeColors } from "../../../utils/constants/themeColors";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  FilteredApiData,
  Locale,
  setCurrentScreen,
  setSelectedBrand,
} from "../../../utils/redux";
import { screenNames } from "../../../utils/constants/screenNames";
import { useEffect, useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Cart,
  FiltredApiDataInterface,
  SelectedEditOrder,
  setSelectedEditOrder,
} from "../../../utils/redux/reducer/order-slice";
import {  OrderHistory } from "../../../utils/types";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../../utils/constants/events";
import dayjs from "dayjs";
import ErrorIcon from "@mui/icons-material/Error";
import {
  calculateProgress,
  getDraftData,
  getTotalUnits
} from "../../../utils/helperFunctions/helperFunction";
import Checkout from "../Checkout/Checkout";

const BrandSelection = () => {
  const locale = useSelector(Locale);
  const dispatch = useDispatch();
  const filtredData = useSelector(FilteredApiData);
  const cartData = useSelector(Cart);
  const selectedEditOrder = useSelector(SelectedEditOrder);
  const [specificOrderData,setSpecificOrderData] = useState<OrderHistory | null>(null);

  const checkIconDisplay = (
    brand: FiltredApiDataInterface,
    checkError: boolean
  ) => {
    if (checkError) {
      const today = dayjs().startOf("day");
      const pastDatesDrafts = cartData.filter(
        (elm) =>
          elm.brandIdentifier === brand.brandIdentifier &&
          dayjs(elm.orderDate).startOf("day").isBefore(today)
      );
      if (pastDatesDrafts.length > 0) {
        return (
          <ErrorIcon
            tabIndex={-1}
            sx={{
              color: "error.main",
              "&:focus": {
                outline: "none",
              },
            }}
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={
              locale?.general?.order?.tooltip?.prevDraftError
            }
            aria-hidden="true"
          />
        );
      }
    } else {
      const isOrderInCart = cartData.find(
        (cartItem) => cartItem.brandIdentifier === brand.brandIdentifier
      );
      if (isOrderInCart) {
        return (
          <WarningIcon
            tabIndex={-1}
            sx={{
              color: "warning.light",
              "&:focus": {
                outline: "none",
              },
            }}
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={
              locale?.general?.order?.tooltip?.confirmDraftChanges
            }
            aria-hidden="true"
          />
        );
      }
    }
  };

  

  const handleCheckoutClick = () => {
    const today = dayjs().startOf("day");
    const pastDatesDrafts = cartData.filter((elm) =>
      dayjs(elm.orderDate).startOf("day").isBefore(today)
    );
    if (pastDatesDrafts.length > 0) {
      sendEvent(eventNames.pastDateDraftsCheckout, pastDatesDrafts);
    } else {
      dispatch(setCurrentScreen(screenNames.checkout));
      sendEvent(eventNames.checkoutScreen, {});
    }
  };

  useEffect(() => {
    dispatch(setSelectedBrand({}));
  }, []);

  useEffect(() => {
    (async () => {
      await getDraftData();
    })();
  }, []);

  useEffect(()=>{
    if(selectedEditOrder){
      setSpecificOrderData(selectedEditOrder);
      dispatch(setSelectedEditOrder(null));
    }
  },[])

  return (
    specificOrderData ? <Checkout showSpecificOrder={specificOrderData}/> : <Box className="flex fdc fullHeight">
      <Box className="brand-container auto-o" padding={"7px 20px"}>
        <Box className="flex ai-c jc-sb">
          <Box className="flex ai-c">
            <Typography
              fontSize={18}
              fontWeight={400}
              sx={{ color: themeColors.primaryType1 }}
            >
              {locale?.general?.brandSelection?.labels?.chooseBrands?.toUpperCase()}
            </Typography>
          </Box>
          <Box>
            {cartData.length === 0 ? (
              <IconButton disabled>
                <ShoppingCartOutlinedIcon className="cart-icon" />
              </IconButton>
            ) : (
              <Box className="relative">
                <IconButton onClick={handleCheckoutClick}>
                  <ShoppingCartIcon className="cart-icon" />
                </IconButton>
                <Box
                  className="absolute t0 r0 font12 cart-quntity flex jc-c ai-c"
                  sx={{ background: themeColors.primaryType1 }}
                >
                  {cartData.length}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="font11 flex g15">
          <Box className="flex ai-c g5">
            <Typography
              fontSize={11}
              fontWeight={700}
              style={{ color: themeColors.primaryType1 }}
            >
              {locale?.general?.common?.labels?.startDate}:
            </Typography>
            <span>{filtredData.startDate}</span>
          </Box>
          <Box className="flex ai-c g5">
            <Typography
              fontSize={11}
              fontWeight={700}
              style={{ color: themeColors.primaryType1 }}
            >
              {locale?.general?.common?.labels?.endDate}:
            </Typography>
            <span>{filtredData.endDate}</span>
          </Box>
        </Box>
        {filtredData.offerData &&
          filtredData.offerData.length > 0 &&
          filtredData.offerData.map((brand, index: number) => {
            return (
              <Box
                className="progress-card-container mt10"
                key={brand.brandIdentifier + index}
              >
                <Box textAlign={"right"} className="flex jc-sb">
                  <Typography fontSize={13} style={{ fontWeight: 500 }}>
                    {brand.brand}
                  </Typography>
                  <Box>
                    {checkIconDisplay(brand, false)}
                    {checkIconDisplay(brand, true)}
                  </Box>
                </Box>
                <Grid container>
                  <Grid item xs>
                    <Box className="relative">
                      <Grid container>
                        <Grid xs item className="relative">
                          <LinearProgress
                            variant="determinate"
                            value={calculateProgress(brand, true, false)}
                            sx={{
                              height: "20px",
                              backgroundColor: "secondary.main",
                              borderRadius: "5px",
                              "& .MuiLinearProgress-bar": {
                                transition: "none",
                                backgroundColor: "primary",
                                borderRadius: "5px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="absolute b0">
                        <Grid xs item className="relative">
                          <LinearProgress
                            variant="determinate"
                            value={calculateProgress(brand, false, false)}
                            sx={{
                              height: "20px",
                              backgroundColor: "transparent",
                              borderRadius: "5px",
                              "& .MuiLinearProgress-bar": {
                                transition: "none",
                                backgroundColor: "#3f3f3f",
                                borderRadius: "5px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    width={"fit-content"}
                    item
                    className="flex jc-c"
                    height={"20px"}
                    alignItems={"center"}
                    gap={"5px"}
                    ml={"5px"}
                  >
                    <Typography width={"25px"} textAlign={"center"}>
                      {getTotalUnits(brand)}
                    </Typography>
                    <Box
                      className="plus-icon-brand-selection"
                      onClick={() => {
                        dispatch(setSelectedBrand(brand));
                        dispatch(setCurrentScreen(screenNames.order));
                        sendEvent(eventNames.brandSelected, brand);
                      }}
                      sx={{
                        background: themeColors.primaryType1,
                      }}
                    >
                      +
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Box>
      <Box
        className="flex jc-c ai-c"
        height={"55px"}
        bgcolor={themeColors.secondaryType1}
      >
        <CustomButton
          disabled={cartData.length === 0}
          onClick={handleCheckoutClick}
          width="130px"
          align="space-around"
        >
          <ShoppingCartOutlinedIcon sx={{ fontSize: 20 }} />
          <Typography fontSize={16}>
            {locale?.general?.brandSelection?.buttons?.checkout}
          </Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default BrandSelection;
