import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import Battery3BarIcon from "@mui/icons-material/Battery3Bar";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CallIcon from "@mui/icons-material/Call";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./style.css";

interface WABAProps{
  botUrl: string;
}

const WABA : FC<WABAProps> = ({botUrl}) => {
  return (
    <Box className="flex jc-c ai-c fullvh" bgcolor={'primary.main'}>
      <Box height={"90%"}>
        <Box className="side-buttons volume-buttom" />
        <Box className="side-buttons power-button" />
      </Box>
      <Box className="phone-container">
        <Box className="upper-phone-space" />
        <Box bgcolor={"#0A8D7F"}>
          <Box className="flex jc-sb pl10 pr10">
            <Typography color={"white"} fontSize={11}>
              11:00 am
            </Typography>
            <Box className="flex g5">
              <Box className="flex">
                <Typography color={"white"} fontSize={11}>
                  5G
                </Typography>

                <NetworkCellIcon sx={{ color: "white", fontSize: 13 }} />
              </Box>
              <Box className="flex">
                <Typography color={"white"} fontSize={11}>
                  50%
                </Typography>
                <Battery3BarIcon sx={{ color: "white", fontSize: 13 }} />
              </Box>
            </Box>
          </Box>
          <Box className="flex jc-sb pl5 pr5 pb5">
            <Box className="flex g5 ai-c">
              <ArrowBackIcon sx={{ color: "white", fontSize: 15 }} />
              <AccountCircleIcon sx={{ color: "white" }} />
              <Typography color={"white"} fontSize={11}>
                USER
              </Typography>
            </Box>
            <Box className="flex ai-c g10">
              <VideoCallIcon sx={{ color: "white" }} />
              <CallIcon sx={{ color: "white", fontSize: 16 }} />
              <MoreVertIcon sx={{ color: "white" }} />
            </Box>
          </Box>
        </Box>
        <iframe
          title="bot-iframe"
          src={botUrl}
          className="waba-flow-iframe"
        />
        <Box className="lower-phone-space" />
      </Box>
    </Box>
  );
};

export default WABA;
