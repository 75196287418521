/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  Cart,
  FilteredApiData,
  Loc,
  Locale,
  RepData,
  setCheckoutData,
  setCurrentScreen,
  setOrderData,
  setSelectedBrand,
  User,
  WabaToken,
} from "../../../utils/redux";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { screenNames } from "../../../utils/constants/screenNames";
import dayjs from "dayjs";
import {
  calculateDiscount,
  getBrandData,
  getSkuAppearOrder,
  getTriad,
} from "../../../utils/helperFunctions/helperFunction";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../../utils/constants/events";
import { FC, useEffect, useState } from "react";
import {
  CartOrder,
  DiscountRanges,
  FilteredCart,
  OrderHistory,
  skuDataWithBrand,
} from "../../../utils/types";
import CheckoutOrderCard from "./CheckoutOrderCard";
import { offerTypes } from "../../../utils/constants/constatns";
import "./styles.css"
// import axios from "axios";

export interface MaxVolumeSku {
  skuIdentifier: string;
  selected: number;
  maxVolume: number;
  closedPurchased: number;
  onInvoiceDiscountRanges: DiscountRanges[];
  offInvoiceDiscountRanges: DiscountRanges[];
  onInvoicePaymentTermRanges: DiscountRanges[];
}
export interface MaxVolumeData {
  totalSelected: number;
  brand: string;
  brandIdentifier: string;
  maxVolume: number;
  offerLevel: string;
  skuUnits: MaxVolumeSku[];
  closedPurchased: number;
  onInvoiceDiscountRanges: DiscountRanges[];
  offInvoiceDiscountRanges: DiscountRanges[];
  onInvoicePaymentTermRanges: DiscountRanges[];
}

interface ChekoutProps {
  showSpecificOrder?: OrderHistory;
}

const Checkout: FC<ChekoutProps> = ({ showSpecificOrder }) => {
  const locale = useSelector(Locale);
  const cart = useSelector(Cart);
  const filtredData = useSelector(FilteredApiData);
  const dispatch = useDispatch();
  const [filterdCart, setFilterdCart] = useState<FilteredCart[]>([]);
  const [maxVolumeData, setMaxVolumeData] = useState<MaxVolumeData[]>([]);
  const loc = useSelector(Loc);
  const user = useSelector(User);
  const repData = useSelector(RepData);
  const [disableDates, setDisableDates] = useState<
    { triad: string; dateToExclude: string[] }[]
  >([]);
  const [disableConfirm,setDisableConfirm] = useState(false);
  const wabaToken = useSelector(WabaToken);

  const handleConfirmOrder = async () => {
    try {
      let initialFilterdCart = filterdCart;
      if(showSpecificOrder){
        initialFilterdCart = filterdCart.filter(filterItem => {
          const isIdExist = filterItem.skuData.find(skuItem => skuItem.orderId===showSpecificOrder.orderId);
          if(isIdExist){
            return true;
          }else{
            return false;
          }
        });
      }
      const commonOrderIdData : FilteredCart[] = [];
      for(let initialItem of initialFilterdCart){
        if(initialItem.orderId){
          const uniqueOrderIds = [...new Set(initialItem.skuData.map(item => item.orderId).filter(orderId => orderId !== null && orderId !== undefined))];          
          if(uniqueOrderIds.length>1){
            for(let i=0; i<uniqueOrderIds.length;i++){
              const skuData = initialItem.skuData.filter(skuItem => skuItem.orderId===uniqueOrderIds[i]);
              if(i===0){
                const nullOrderIdData = initialItem.skuData.filter(skuItem => !skuItem.orderId);
                commonOrderIdData.push({...initialItem, orderId: uniqueOrderIds[i] as number, skuData: [...skuData,...nullOrderIdData]})
              }else{
                commonOrderIdData.push({...initialItem, orderId: uniqueOrderIds[i] as number, skuData: [...skuData]})
              }
            }
          }else{
            commonOrderIdData.push(initialItem);
          }
        }else{
          commonOrderIdData.push(initialItem);
        }
      }

      const orderDraftDetails = commonOrderIdData.map((cartItem) => {
        let orderStatus;
        const wholesaler = filtredData.accounts.find(
          (account) => getTriad(account) === getTriad(cartItem)
        );
        if (
          wholesaler?.customerServiceAccountStatus === "PENDING_VERIFICATION"
        ) {
          orderStatus = "CONDITIONAL";
        } else if (cartItem.orderDate <= dayjs().format("YYYY-MM-DD")) {
          orderStatus = "IN_PROCESS";
        } else {
          orderStatus = "AWAITING_DATE";
        }
        const skuData :skuDataWithBrand[] = []
        for(let skuItem of cartItem.skuData){
          const brandInfo = filtredData.offerData.find(elm => elm.brandIdentifier===skuItem.brandIdentifier);
          const onInvoice = getDiscount(skuItem, cartItem, 'onInvoice');
          const offInvoice = getDiscount(skuItem, cartItem, 'offInvoice');
          skuData.push({
            ...skuItem,
             onInvoiceDiscount: brandInfo?.onInvoiceDiscountType?.toLowerCase()==='free_goods' ? 0 : onInvoice,
             onInvoiceFreeGoods: brandInfo?.onInvoiceDiscountType?.toLowerCase()==='free_goods' ? Math.ceil(onInvoice) : 0,
             offInvoiceDiscount: brandInfo?.offInvoiceDiscountType?.toLowerCase()==='free_goods' ? 0 : offInvoice,
             offInvoiceFreeGoods: brandInfo?.offInvoiceDiscountType?.toLowerCase()==='free_goods' ? Math.ceil(offInvoice) : 0,
             onInvoicePaymentTerms: getDiscount(skuItem, cartItem, 'payTerms')
            });
        }
        return {
          ...cartItem,
          externalOrderStatus: orderStatus,
          orderId: cartItem.orderId || -1,
          internalOrderStatus: "OPEN",
          noOfPharmaciesOrder: 1,
          skuData
        };
      });      
      await axiosInstance.post(URLS.draftToOrder, {
        orderDraftDetails,
        editOrderId : showSpecificOrder ? showSpecificOrder.orderId : null,
        isWaba: wabaToken ? true : false
      });
      if(wabaToken){
        await getBrandData();
        dispatch(setOrderData([]));
        dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
      }else{
      dispatch(setOrderData([]));
      dispatch(setCurrentScreen(screenNames.userWelcomeScreen));
      sendEvent(eventNames.confirmCheckout, orderDraftDetails);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPreviousOrderData = (filterdCartData: FilteredCart[]) => {
    const prevOrderData: FilteredCart[] = [];
    for (let openOrder of filtredData.openOrders) {
      let isExist;
      for (let lineItem of openOrder.orderLineItems) {
        for (let filterItem of filterdCartData) {
          isExist = filterItem.skuData.find(
            (skuItem) => skuItem.lineId === lineItem.id
          );
          if (isExist) {
            break;
          }
        }
        if (isExist) {
          break;
        }
      }
      const skuData: skuDataWithBrand[] = openOrder.orderLineItems.map(
        (lineItem) => {
          const findBrand = filtredData.offerData.find(elm => elm.brandIdentifier===lineItem.brandIdentifier);
          return {
            skuIdentifier: lineItem.skuIdentifier,
            sku: lineItem.sku,
            requestedVolume: lineItem.units,
            onInvoiceDiscount: lineItem.onInvoiceDiscount || 0,
            price: lineItem.price,
            uniqueId: null,
            lineId: lineItem.id,
            brand: lineItem.brand,
            brandIdentifier: lineItem.brandIdentifier,
            orderId: lineItem?.id ? searchOrderIdByLineId(lineItem.id) : null,
            externalOrderStatus: lineItem?.id ? searchStatusByLineId(lineItem.id) : null,
            skuAppearOrder: getSkuAppearOrder(findBrand, lineItem.skuIdentifier),
            brandAppearOrder: findBrand?.brandAppearOrder || -1
          };
        }
      )
      .sort((a, b) => {
        if (a.brandAppearOrder !== b.brandAppearOrder) {
          return a.brandAppearOrder - b.brandAppearOrder;
        }
        return a.skuAppearOrder - b.skuAppearOrder;
      });
      if (!isExist) {
        prevOrderData.push({
          loc: loc,
          orderType: "ORDER",
          phoneNumber: user.phone,
          accountCode: openOrder.accountCode,
          orderDate: openOrder.orderedDateString,
          externalOrderStatus: openOrder.status,
          orderPlacedBy: repData.phone || user.phone,
          orderId: openOrder.orderId,
          draftId: -1,
          legalIdCode: openOrder.legalIdCode,
          wholesalerCode: openOrder.wholesalerCode,
          wholesalerName: openOrder.wholesalerName,
          isDraft: false,
          indexValue: -1,
          skuData,
        });
      }
    }
    return prevOrderData;
  };

  const searchOrderIdByLineId = (lineId: number) =>{
    let orderId = null;
    for(let openOrder of filtredData.openOrders){
      const findLineId = openOrder.orderLineItems.find(lineItem => lineItem.id===lineId);
      if(findLineId){
        orderId = openOrder.orderId;
        break;
      }
    }
    return orderId;
  }

  const searchStatusByLineId = (lineId: number) =>{
    let status = null;
    for(let openOrder of filtredData.openOrders){
      const findLineId = openOrder.orderLineItems.find(lineItem => lineItem.id===lineId);
      if(findLineId){
        status = openOrder.status;
        break;
      }
    }
    return status;
  }

  useEffect(() => {
    if (cart) {
      const finalFilteredData = [];
      const uniqueCartRecord: CartOrder[] = [];
      for (let cartItem of cart) {
        const isExist = uniqueCartRecord.find(
          (uniqueRecord) =>
            uniqueRecord.orderDate === cartItem.orderDate &&
            getTriad(uniqueRecord) === getTriad(cartItem)
        );

        if (!isExist) {
          const isOrderIdCartItemExist = cart.find(
            (elm) =>
              elm.orderDate === cartItem.orderDate &&
              getTriad(elm) === getTriad(cartItem) &&
              elm.orderId
          );
          let orderId = null;
          if (isOrderIdCartItemExist) {
            orderId = isOrderIdCartItemExist.orderId;
          }
          uniqueCartRecord.push({ ...cartItem, orderId });
        }
      }      

      for (let uniqueRecord of uniqueCartRecord) {
        const filteredRecord = cart.filter(
          (cartItem) =>
            cartItem.orderDate === uniqueRecord.orderDate &&
            getTriad(cartItem) === getTriad(uniqueRecord)
        );
        let combinedFilteredSkuData: skuDataWithBrand[] = [];
        for (let filterItem of filteredRecord) {
          const filteredSkuData = filterItem.skuData.map((skuItem) => {
            const findBrand = filtredData.offerData.find(elm => elm.brandIdentifier===filterItem.brandIdentifier);
            return {
              ...skuItem,
              brand: filterItem.brand,
              brandIdentifier: filterItem.brandIdentifier,
              orderId: skuItem?.lineId ? searchOrderIdByLineId(skuItem.lineId) : null,
              externalOrderStatus: skuItem?.lineId ? searchStatusByLineId(skuItem.lineId) : null,
              skuAppearOrder: getSkuAppearOrder(findBrand, skuItem.skuIdentifier),
              brandAppearOrder: findBrand?.brandAppearOrder || -1
            };
          });
          combinedFilteredSkuData.push(...filteredSkuData);
        }
        const { skuData, brand, brandIdentifier, ...restOfRecord } =
          uniqueRecord;            
        finalFilteredData.push({
          ...restOfRecord,
          skuData: combinedFilteredSkuData.sort((a, b) => {
            if (a.brandAppearOrder !== b.brandAppearOrder) {
              return a.brandAppearOrder - b.brandAppearOrder;
            }
            return a.skuAppearOrder - b.skuAppearOrder;
          }),
        });
      }
      if (showSpecificOrder) {
        const prevOrderData = getPreviousOrderData(finalFilteredData);
        setFilterdCart([...finalFilteredData, ...prevOrderData]);
      } else {
        setFilterdCart(finalFilteredData);
      }
    }    
  }, [cart]);

  useEffect(() => {
    if (filterdCart) {
      const updatedDisableDates = [];
      for (let account of filtredData.accounts) {
        const datesToDisable = filterdCart
          .filter((filterItem) => getTriad(filterItem) === account.triad)
          .map((filterItem) => filterItem.orderDate);
        updatedDisableDates.push({
          triad: account.triad,
          dateToExclude: datesToDisable,
        });
      }
      setDisableDates(updatedDisableDates);
    }
  }, [filterdCart]);

  useEffect(() => {
    const brandUnitData: MaxVolumeData[] = [];
    let prevOrderData: FilteredCart[] = [];
    if (!showSpecificOrder) {
      prevOrderData = getPreviousOrderData(filterdCart);
    }
    for (let brandItem of filtredData.offerData) {
      const skuUnitData: MaxVolumeSku[] = [];
      let totalSelected = 0;
      for (let skuItem of brandItem.skuData) {
        let selected = 0;
        for (let filterItem of [...filterdCart, ...prevOrderData]) {
          for (let filterSkuItem of filterItem.skuData) {
            if (
              filterSkuItem.skuIdentifier === skuItem.skuIdentifier &&
              skuItem.brandIdentifier === filterSkuItem.brandIdentifier
            ) {
              selected = selected + filterSkuItem.requestedVolume;
            }
          }
        }
        skuUnitData.push({
          onInvoiceDiscountRanges: skuItem.onInvoiceDiscountRanges,
          skuIdentifier: skuItem.skuIdentifier,
          selected,
          maxVolume: skuItem.maxVolume,
          closedPurchased: skuItem.closedPurchased,
          offInvoiceDiscountRanges: skuItem.offInvoiceDiscountRanges,
          onInvoicePaymentTermRanges: skuItem.onInvoicePaymentTermRanges
        });
        totalSelected = selected + totalSelected;
      }
      brandUnitData.push({
        offInvoiceDiscountRanges: brandItem.offInvoiceDiscountRanges,
        onInvoiceDiscountRanges: brandItem.onInvoiceDiscountRanges,
        closedPurchased: brandItem.closedPurchased,
        offerLevel: brandItem.offerLevel,
        totalSelected,
        maxVolume: brandItem.maxVolume,
        brand: brandItem.brand,
        brandIdentifier: brandItem.brandIdentifier,
        skuUnits: skuUnitData,
        onInvoicePaymentTermRanges: brandItem.onInvoicePaymentTermRanges
      });
    }
    setMaxVolumeData(brandUnitData);
  }, [filterdCart]);

  const getDiscount = (sku: skuDataWithBrand, totalSkuData: FilteredCart, type: 'onInvoice' | 'offInvoice' | 'payTerms') => {
    const brand = filtredData.offerData.find(
      (elm) => elm.brandIdentifier === sku.brandIdentifier
    );
    let rangeData : DiscountRanges[] = [];
    if (brand) {
      let discount: string | number = 0;
      let selected = 0;
      if (showSpecificOrder) {
        const pastOrders = filtredData.openOrders.filter(
          (openOrder) =>
            openOrder.currentPeriod &&
            openOrder.orderId !== showSpecificOrder.orderId
        );
        if (brand.offerLevel?.toLowerCase() === offerTypes.skuLevel) {
          if (pastOrders.length > 0) {
            for (let pastOrder of pastOrders) {
              for (let lineItem of pastOrder.orderLineItems) {
                if (
                  lineItem.skuIdentifier === sku.skuIdentifier &&
                  lineItem.brandIdentifier === sku.brandIdentifier
                ) {
                  selected = selected + lineItem.units;
                }
              }
            }
          }
          const skuItem = brand.skuData.find(
            (elm) => elm.skuIdentifier === sku.skuIdentifier
          );
          if (skuItem) {
            if(type==='onInvoice'){
              rangeData = skuItem.onInvoiceDiscountRanges;
            }else if(type==='offInvoice'){
              rangeData = skuItem.offInvoiceDiscountRanges
            }else if(type==='payTerms'){
              rangeData = skuItem.onInvoicePaymentTermRanges;
            }
            discount = calculateDiscount(rangeData, sku.requestedVolume + selected + skuItem.closedPurchased);
          }
          return discount as number;
        } else {
          if (pastOrders.length > 0) {
            for (let pastOrder of pastOrders) {
              for (let lineItem of pastOrder.orderLineItems) {
                if (lineItem.brandIdentifier === sku.brandIdentifier) {
                  selected = selected + lineItem.units;
                }
              }
            }
          }
          let currentSelected = 0;
          for (let skuElement of totalSkuData.skuData) {
            if (skuElement.brandIdentifier === brand.brandIdentifier) {
              currentSelected = currentSelected + skuElement.requestedVolume;
            }
          }

          if(type==='onInvoice'){
            rangeData = brand.onInvoiceDiscountRanges;
          }else if(type==='offInvoice'){
            rangeData = brand.offInvoiceDiscountRanges
          }else if(type==='payTerms'){
            rangeData = brand.onInvoicePaymentTermRanges;
          }
          discount = calculateDiscount(rangeData, currentSelected + selected + brand.closedPurchased);
          return discount as number;
        }
      } else {
        // Calculate all card discount
        if (brand.offerLevel?.toLowerCase() === offerTypes.skuLevel) {
          for (let maxVolumeItem of maxVolumeData) {
            if (maxVolumeItem.brandIdentifier === brand.brandIdentifier) {
              const skuMaxItem = maxVolumeItem.skuUnits.find(
                (skuUnit) => skuUnit.skuIdentifier === sku.skuIdentifier
              );
              if (skuMaxItem) {
                if(type==='onInvoice'){
                  rangeData = skuMaxItem.onInvoiceDiscountRanges;
                }else if(type==='offInvoice'){
                  rangeData = skuMaxItem.offInvoiceDiscountRanges
                }else if(type==='payTerms'){
                  rangeData = skuMaxItem.onInvoicePaymentTermRanges;
                }
                discount = calculateDiscount(
                  rangeData,
                  skuMaxItem.selected + skuMaxItem.closedPurchased
                );
              }
            }
          }
        } else {
          const brandMaxItem = maxVolumeData.find(
            (maxItem) => maxItem.brandIdentifier === brand.brandIdentifier
          );
          if (brandMaxItem) {
            if(type==='onInvoice'){
              rangeData = brandMaxItem.onInvoiceDiscountRanges;
            }else if(type==='offInvoice'){
              rangeData = brandMaxItem.offInvoiceDiscountRanges
            }else if(type==='payTerms'){
              rangeData = brandMaxItem.onInvoicePaymentTermRanges;
            }
            discount = calculateDiscount(
              rangeData,
              brandMaxItem.closedPurchased + brandMaxItem.totalSelected
            );
          }
        }
        return discount as number;
      }
    } else {
      return 0;
    }
  };

  useEffect(()=>{
    if(showSpecificOrder){
      const specificOrders = filterdCart.filter(filterItem => {
        const isOrderIdExist = filterItem.skuData.find(skuItem => skuItem.orderId===showSpecificOrder.orderId);
        if(isOrderIdExist){
          return true;
        }else{
          return false;
        }
      });
      if(specificOrders.length===0){
        setDisableConfirm(true);  
      }else{
        setDisableConfirm(false);
      }
    }else if(filterdCart.length===0){
      setDisableConfirm(true);
    }else{
      setDisableConfirm(false);
    }
  },[filterdCart]);


  useEffect(()=>{
    dispatch(setCheckoutData(filterdCart));
  },[filterdCart])

  return (
    <Box className="flex fdc fullHeight borderBox">
      <Box className="checkout-card-container auto-o pl20 pr20">
        <Typography color={"primary"} fontSize={20} mt={0.5}>
          {locale?.general?.brandSelection?.buttons?.checkout}
        </Typography>
        {showSpecificOrder
          ? filterdCart.map((filterdCartItem, index) => {
            const isOrderIdExist = filterdCartItem.skuData.find(skuItem => skuItem.orderId===showSpecificOrder.orderId);
            if(isOrderIdExist){
              return <Box mt={1} key={index}>
              <CheckoutOrderCard
                getDiscount={getDiscount}
                maxVolumeData={maxVolumeData}
                indexValue={index}
                filterdCart={filterdCart}
                setFilterdCart={setFilterdCart}
                filterdCartItem={filterdCartItem}
                disableDates={disableDates}
              />
            </Box>
            }else{
              return "";
            }
            })
          : filterdCart.map((filterdCartItem, index) => {
              return (
                <Box mt={1} key={index}>
                  <CheckoutOrderCard
                    getDiscount={getDiscount}
                    maxVolumeData={maxVolumeData}
                    indexValue={index}
                    filterdCart={filterdCart}
                    setFilterdCart={setFilterdCart}
                    filterdCartItem={filterdCartItem}
                    disableDates={disableDates}
                  />
                </Box>
              );
            })}
      </Box>
      <Box className="flex jc-c g20 pt5 pb10">
        <CustomButton
          onClick={() => {
            if (showSpecificOrder) {
              dispatch(setSelectedBrand(null));
              dispatch(setCurrentScreen(screenNames.orderHistory));
            } else {
              dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
              sendEvent(eventNames.backToBrandSelection, {});
            }
          }}
          align="space-evenly"
          width={"155px"}
          background="#e6e6e6"
          color="#959595"
          hoverBackground="#d2cece"
        >
          <ArrowCircleLeftOutlinedIcon />
          <Typography fontSize={16}>
            {locale?.general?.common?.buttons?.back}
          </Typography>
        </CustomButton>
        <CustomButton
          disabled={disableConfirm}
          onClick={handleConfirmOrder}
          align={"space-evenly"}
          width={"155px"}
        >
          <Typography fontSize={16}>{locale?.general?.common?.buttons?.confirm}</Typography>
          <ArrowCircleRightOutlinedIcon />
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Checkout;
