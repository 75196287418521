import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
// import { fetchFromStorage } from "../storage";
// import { identifiers } from "../constants/identifiers";
import Config from "../config";
import { fetchFromStorage } from "../storage";
import { identifiers } from "../constants/identifiers";
import store from "../redux";
// import store from "../redux";
// import { logout, setCurrentScreen } from "../redux/reducer/authentication-slice";
// import { screenNames } from "../constants/screenNames";

const axiosInstance = Axios.create({
  baseURL: Config.BaseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  let token;
  const state = store.getState();
  const authData = state.authenticationSlice;
  if(authData.token){
    token = authData.token;
  }else{
    token = fetchFromStorage(identifiers.token);
  }
  const clonedConfig: any = config;
  
  if (token) {
    clonedConfig.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return clonedConfig;
});
axiosInstance.interceptors.response.use(
  (config: AxiosResponse) => {
    return config;
  },
  (error: AxiosError) => {
    // Handle token expire
    // if(error.response?.status===401){
    //   store.dispatch(logout()); 
    //   store.dispatch(setCurrentScreen(screenNames.welcomeScreen));
    // }    
      return Promise.reject(error);
  }
);

export default axiosInstance;
