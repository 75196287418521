import { createSlice } from "@reduxjs/toolkit";
import {
  clearStorage,
  fetchFromStorage,
  removeFromStorage,
  saveToStorage,
} from "../../storage";
import { identifiers } from "../../constants/identifiers";
import { UserProfileInterface } from "../../types";

const loggendInStatus = fetchFromStorage(identifiers.token as any);
const user = fetchFromStorage(identifiers.user as any);
const rep = fetchFromStorage(identifiers.rep as any);
interface AuthenticationState {
  user: any;
  authenticated: boolean;
  currentScreen: string;
  profileDetails: UserProfileInterface;
  botEventData: any;
  rep: any;
  token: string;
}

const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: {
    authenticated: loggendInStatus || false,
    token: null,
    user: user ? JSON.parse(user) : {},
    currentScreen: "",
    profileDetails: {
      profileDetails: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        otherInformation: false,
        marketingCampaigns: false,
        transactional: false,
        sms: false,
        electronicMarketingCampaigns: false,
        instantMessageConsent: false,
      },
      associatedAccounts: [
        {
          wholeSalerCode: "",
          wholeSalerName: "",
          wholeSalerStatus: "",
          accounts: [
            {
              accountNumber: "",
              legalIdCode: "",
              invoice: "",
              accountStatus: "",
            },
          ],
        },
      ],
      notification: false,
    },
    botEventData: {},
    rep: rep ? JSON.parse(rep) : {}
  },
  reducers: {
    setAuthentication(state, action) {
      if (action.payload) {
        state.authenticated = true;
        saveToStorage(identifiers.token, action.payload);
      } else {
        state.authenticated = false;
        if (fetchFromStorage(identifiers.token)) {
          removeFromStorage(identifiers.token);
        }
      }
    },
    logout(state) {
      const currentLang = localStorage.getItem("current-lang");
      clearStorage("all");
      if (currentLang) {
        localStorage.setItem("current-lang", currentLang);
      }
      state.authenticated = false;
      state.user = {};
      state.rep = {};
    },
    setUser(state, action) {
      state.user = action.payload;
      saveToStorage(identifiers.user, JSON.stringify(action.payload));
    },
    setCurrentScreen(state, action) {
      state.currentScreen = action.payload;
    },
    setUserProfile(state, action) {
      state.profileDetails = action.payload;
    },
    setBotEventData(state,action){
      state.botEventData = action.payload;
    },
    setRepData(state,action){
      state.rep = action.payload;
      saveToStorage(identifiers.rep, JSON.stringify(action.payload));
    },
    setWabaToken(state,action){
      state.token = action.payload.token;
      state.user = action.payload.user;
    }
  },
});

export default authenticationSlice.reducer;

export const {
  setAuthentication,
  logout,
  setUser,
  setCurrentScreen,
  setUserProfile,
  setBotEventData,
  setRepData,
  setWabaToken
} = authenticationSlice.actions;

export const User = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.user;
export const isAuthenticated = (state: {
  authenticationSlice: AuthenticationState;
}) => state.authenticationSlice.authenticated;
export const CurrentScreen = (state: {
  authenticationSlice: AuthenticationState;
}) => state.authenticationSlice.currentScreen;

export const ProfileDetails = (state: {
  authenticationSlice: AuthenticationState;
}) => state.authenticationSlice.profileDetails;

export const BotEventData = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.botEventData;

export const RepData = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.rep;

export const WabaToken = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.token;