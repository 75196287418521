import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RootScreen from "./screens/RootScreen";
import "./assets/css/global.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import WABA from "./screens/Authenticated/WABA/WABA";
import WhatsAppOrder from "./screens/Authenticated/WhatsAppOrder/WhatsAppOrder";
import { envConfig } from "./utils/envConfig";
import NotFound from "./screens/NotFound/NotFound";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f26633",
      light: "#F7EAE1",
    },
    secondary: {
      main: "#959595",
    },
    success: {
      main: "#008a00",
      light: "#4caf50",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
    },
  },
  typography: {
    fontSize: 12,
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {envConfig?.PRIMARY_LOC && <BrowserRouter>
          <Routes>
            <Route path="/" element={<RootScreen loc={envConfig.PRIMARY_LOC} botUrl={envConfig?.BOT_URL || ""}/>} />
            <Route path="/order" element={<WhatsAppOrder loc={envConfig?.PRIMARY_LOC || ""} />} />
            <Route path="*" element={<NotFound loc={envConfig.PRIMARY_LOC}/>} />
            {envConfig?.WABA_BOT_URL && <Route path="/waba" element={<WABA botUrl={envConfig.WABA_BOT_URL}/>} />}
            {envConfig?.CHILE_BOT_URL && <Route path="/chile" element={<RootScreen loc={envConfig?.CHILE_BOT_LOC || ""} botUrl={envConfig.CHILE_BOT_URL}/>} />}
            {envConfig?.PERU_BOT_URL && <Route path="/peru" element={<RootScreen loc={envConfig?.PERU_BOT_LOC || ""} botUrl={envConfig.PERU_BOT_URL}/>} />}
            {envConfig?.ECUADOR_BOT_URL && <Route path="/ecuador" element={<RootScreen loc={envConfig?.ECUADOR_BOT_LOC || ""} botUrl={envConfig.ECUADOR_BOT_URL}/>} />}
            {envConfig?.SPAIN_BOT_URL && <Route path="/spain" element={<RootScreen loc={envConfig?.SPAIN_BOT_LOC || ""} botUrl={envConfig.SPAIN_BOT_URL}/>} />}
            {envConfig?.PORTUGAL_BOT_URL && <Route path="/portugal" element={<RootScreen loc={envConfig?.PORTUGAL_BOT_LOC || ""} botUrl={envConfig.PORTUGAL_BOT_URL}/>} />}
          </Routes>
        </BrowserRouter>}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
