/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import {
  findLocaleLang,
  getBrandData,
} from "../../../utils/helperFunctions/helperFunction";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentScreen,  
  isLoading,
  Loc,
  Locale,
  setCurrentScreen,
  setLoading,
  setLoc,
  setLocale,
  setUser,
  setWabaToken,
  User,
} from "../../../utils/redux";
import { screenNames } from "../../../utils/constants/screenNames";
import { Box } from "@mui/material";
import { Tooltip } from "react-tooltip";
import LoadingIcon from "../../../components/LoadingIcon/LoadingIcon";
import changeScreen from "../../../utils/hooks/useChangeScreen";
import { useLocation } from "react-router-dom";

interface RootScreenProps {
  loc: string;
}

const WhatsAppOrder: FC<RootScreenProps> = ({ loc }) => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const currentScreen = useSelector(CurrentScreen);
  const Screen = changeScreen(currentScreen);
  const locName = useSelector(Loc);
  const location = useLocation();
  const user = useSelector(User);
  const [linkValid, setLinkValid] = useState(true);
  const locale = useSelector(Locale);


  const getLocalizationsData = async () => {
    try {
      const { data } = await axiosInstance(URLS.localization(loc));
      if (data) {
        const findLocale = findLocaleLang(data);
        if (findLocale) {
          dispatch(setLocale(findLocale));
          dispatch(setLoc(loc));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataFromId = async () =>{
    try {
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get('id');
      if(id){
        dispatch(setLoading(true));
        const { data } = await axiosInstance(URLS.getWabaData(id));
        if(data?.pharmacyUserResponse && data?.accessToken){
          setLinkValid(true);
          dispatch(setUser({wabaData:data.pharmacyUserResponse}))
          dispatch(setWabaToken({token:data.accessToken,
            user: {
              ...data.pharmacyUserResponse,
              user_id: data.pharmacyUserResponse.userIdentifier,
            phone: data.pharmacyUserResponse.phoneNumber
            }
          }))
        }else{
          setLinkValid(false);
        }
      }else{
        setLinkValid(false);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      setLinkValid(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getLocalizationsData();
  }, []);

  useEffect(() => {
    if (locName) {
      getDataFromId();
    }
  }, [locName]);

  useEffect(() => {
    if (user?.phoneNumber && user?.userIdentifier) {
      getBrandData();
      dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
    }
  }, [user]);  

  return (
    linkValid ? <Box className="flex">
      <Tooltip
        id="custom-tooltip"
        place="bottom"
        style={{
          background: "black",
          opacity: 1,
          padding: "5px 10px",
          zIndex: 9999999,
        }}
      />
      <Box className="fullvh flex fdc" width={"100%"}>
        {loading && <LoadingIcon fullScreen />}
        <Box height={"100%"} sx={{ overflowY: "auto", paddingTop:'20px' }}>
          {Screen}
        </Box>
      </Box>
    </Box> : <Box className="fullvh flex fdc ai-c jc-c" width={"100%"}>{locale?.general?.whatsAppOrder?.labels?.expiredLink}</Box>
  );
};

export default WhatsAppOrder;
